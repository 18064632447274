import { alpha, createTheme } from '@mui/material/styles'
import { getBaseColors } from './utils'

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary']
  }

  interface PaletteOptions {
    neutral?: PaletteOptions['primary']
  }
}

declare module '@mui/material/styles' {
  interface PaletteColor {
    90?: string
    80?: string
    70?: string
    60?: string
    50?: string
    40?: string
    30?: string
    20?: string
    10?: string
    5?: string
  }

  interface SimplePaletteColorOptions {
    90?: string
    80?: string
    70?: string
    60?: string
    50?: string
    40?: string
    30?: string
    20?: string
    10?: string
    5?: string
  }
}

// Config
export const config = {
  drawerWidth: 220,
  headerHeight: 60,
}

// Base colors
export const baseColors = {
  primary: '#0078ff', // Example color: #2b58fd
  secondary: '#ebebeb',
  success: '#4caf50',
  error: '#ef5350',
  warning: { main: '#ff9800', dark: '#f57c00' },
}

// Create a theme instance.
const mainTheme = createTheme({
  palette: {
    ...getBaseColors(baseColors),
    info: {
      main: '#007BFF',
    },
    neutral: {
      main: '#bababa',
      dark: '#767676',
      light: '#efefef',
      90: alpha('#bababa', 0.9),
      80: alpha('#bababa', 0.8),
      70: alpha('#bababa', 0.7),
      60: alpha('#bababa', 0.6),
      50: alpha('#bababa', 0.5),
      40: alpha('#bababa', 0.4),
      30: alpha('#bababa', 0.3),
      20: alpha('#bababa', 0.2),
      10: alpha('#bababa', 0.1),
      5: alpha('#bababa', 0.05),
    },
  },
  typography: {
    fontSize: 12.25,
  },
})

export default mainTheme
