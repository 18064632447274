import '../styles/globals.css'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import Head from 'next/head'
import { GraphQLProvider } from '../src/lib/apollo'
import ApplicationInsights, { setAuthenticatedUser } from '../src/lib/applicationinsights'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { AppProps } from 'next/app'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../src/layout/theme'
import MainLayout, { UnauthorizedLayout } from '../src/layout'
import { CssBaseline } from '@material-ui/core'
import createEmotionCache from '../src/layout/createEmotionCache'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Login from 'src/components/Login'
import { Error, Loading } from 'src/layout/Loading'
import '../src/lib/i18n'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

export interface StyraAppProps extends AppProps {
  emotionCache?: EmotionCache
}

function StyraApp(props: StyraAppProps): JSX.Element {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ApplicationInsights>
          <Head>
            <title>Stýra</title>
            <link rel="icon" href="/favicon.ico" />
          </Head>
          <Auth0Provider
            domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN || ''}
            clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENTID || ''}
            authorizationParams={{
              redirect_uri: process.browser ? window.location.origin + '/callback' : null,
              audience: 'hasura',
            }}
          >
            <GraphQLProvider>
              <AppInitializer Component={Component} pageProps={pageProps} />
            </GraphQLProvider>
          </Auth0Provider>
        </ApplicationInsights>
      </ThemeProvider>
    </CacheProvider>
  )
}

export default StyraApp

const AppInitializer = ({ Component, pageProps }): JSX.Element => {
  const { user, isLoading, isAuthenticated } = useAuth0()
  const { ready, i18n } = useTranslation()
  const translationError = i18n.failedLoading

  //  Ensure that the component does not render until both the server and client are ready
  const [isHydrated, setIsHydrated] = useState(false)
  useEffect(() => {
    setIsHydrated(true)
  }, [])

  const renderUnauthorizedContent = () =>
    translationError ? (
      <Error
        title="Service Unavailable"
        message="We're currently experiencing connection issues. Please try refreshing the page in a few minutes or contact support."
      />
    ) : (
      <Loading message="" />
    )

  if (!isHydrated) {
    return null
  }

  if (!isAuthenticated && !isLoading) {
    return <Login />
  }

  if (translationError || !ready || isLoading) {
    return <UnauthorizedLayout>{renderUnauthorizedContent()}</UnauthorizedLayout>
  }

  setAuthenticatedUser(
    user['https://hasura.io/jwt/claims']?.['x-hasura-ta-user-id'] ||
      'no ta-user-id in auth0 authorisation'
  )

  return <MainLayout Component={Component} pageProps={pageProps} />
}
