import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'
import { addDependencyInitializer, exception } from './applicationinsights'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

const client = new ApolloClient({
  cache: new InMemoryCache(),
})

// Supress normal graphql dependency calls
addDependencyInitializer((details) => {
  if (details.item.target === process.env.NEXT_PUBLIC_X_HASURA_HTTPS_URI) {
    if (details.item?.properties?.requestHeaders?.graphqlname) {
      details.item.name = details.item.properties.requestHeaders.graphqlname
      delete details.item.properties.requestHeaders.graphqlname
    }
  }
})

export const GraphQLProvider = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_X_HASURA_HTTPS_URI || '',
    fetch,
  })

  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.NEXT_PUBLIC_X_HASURA_WSS_URI || '',

      // Configure the JWT into the header
      connectionParams: async () => {
        const token = isAuthenticated ? await getAccessTokenSilently() : null
        return {
          headers: {
            authorization: token ? `Bearer ${token}` : null,
          },
        }
      },
      lazy: true,
    })
  )

  const authLink = setContext(async (operation, { headers }) => {
    const token = isAuthenticated ? await getAccessTokenSilently() : null
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        graphqlname: `${(operation.query?.definitions?.[0] as any)?.operation || 'GraphQL'} ${
          operation.operationName
        }`,
      },
    }
  })

  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    const operationName = `${(operation.query?.definitions?.[0] as any)?.operation || 'GraphQL'} ${
      operation.operationName
    }`

    if (networkError) {
      exception(new Error(`[GraphQL Network error]: ${networkError}`), {
        ...networkError,
        operationName,
      })
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, extensions }) => {
        exception(new Error(`[GraphQL error]: ${extensions.code}: ${message}`), {
          message,
          ...extensions,
          operationName,
        })
      })
    }
  })

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpLink
  )

  client.setLink(errorLink.concat(authLink.concat(splitLink)))

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
